import { gql } from "graphql-tag";
import { PAYMENT_METHOD_FIELDS } from "../graphql/Fragments";

export const CREATE_PAYMENT_METHOD = gql`
	mutation CreatePaymentMethod($input: PaymentMethodInput!) {
		create_payment_method(payment_method: $input) {
			...PaymentMethodFields
		}
	}
	${PAYMENT_METHOD_FIELDS}
`;

export const UPDATE_PAYMENT_METHOD = gql`
	mutation CreatePaymentMethod($input: PaymentMethodInput!) {
		update_payment_method(payment_method: $input) {
			...PaymentMethodFields
		}
	}
	${PAYMENT_METHOD_FIELDS}
`;

export const UPDATE_PAYMENT_POSITION = gql`
	mutation UpdatePaymentPosition($input: UpdatePaymentPositionInput!) {
		update_payment_position(positions: $input)
	}
`;

export const DELETE_PAYMENT_METHOD = gql`
	mutation DeletePaymentMethod($id: Int!) {
		delete_payment_method(id: $id)
	}
`;

export default { CREATE_PAYMENT_METHOD, UPDATE_PAYMENT_METHOD, UPDATE_PAYMENT_POSITION, DELETE_PAYMENT_METHOD };
