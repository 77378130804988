import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaymentMethodsList = _resolveComponent("PaymentMethodsList")!
  const _component_AddPaymentMethod = _resolveComponent("AddPaymentMethod")!
  const _component_EditPaymentMethod = _resolveComponent("EditPaymentMethod")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode(_component_PaymentMethodsList)
    ]),
    _createVNode(_component_AddPaymentMethod),
    _createVNode(_component_EditPaymentMethod)
  ], 64))
}