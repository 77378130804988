
import {setPageHeader} from "@/core/helpers/toolbar";
import { defineComponent } from 'vue'
import PaymentMethodsList from '../components/PaymentMethodsList.vue'
import AddPaymentMethod from "../components/AddPaymentMethod.vue"
import EditPaymentMethod from "../components/EditPaymentMethod.vue"

export default defineComponent({
    components:{
      PaymentMethodsList,
      AddPaymentMethod,
      EditPaymentMethod
    },

    setup() {
        setPageHeader({
            title: "message.PAYMENT_METHODS",
            actionButton: {
                ability:'add_payment_method',
                pageAction: {
                    action: "addPaymentMethodAction",
                },
                button: {
                    icon: "bi bi-plus",
                    openModal: true,
                }
            },
            breadCrumbs: [
                { name: "message.HOME", link: "/dashboard"},
                { name: "message.SYSTEM", link: "#"},
                { name: "message.SETTINGS", link: "/system/settings"},
                { name: "message.PAYMENT_METHODS"}
            ]
        });

    },
})
