
import { Modal } from "bootstrap";
import { defineComponent, ref, inject, computed, watchEffect } from "vue";
import { Input, Select, Image } from "@/components/input-elements";
import { EditPaymentMethod } from "@/modules/system/payment-methods/interfaces/index";
import { Apollo, Notify } from "@/core/services";
import { UPDATE_PAYMENT_METHOD } from "../graphql/Mutations";
import { GET_PAYMENT_METHODS } from "../graphql/Queries";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { GET_LOCALES } from "@/modules/common/locales/graphql/Queries";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "edit payment method",
	components: {
		Input,
		Select,
		Image,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const locale = ref("da");
		const locales = ref([]) as Record<any, any>;
		const emitter: any = inject("emitter");
		const i18n = useI18n();
		const editPaymentMethodForm = ref<null | HTMLFormElement>(null);
		const previewImage = ref([]) as Record<any, any>;
		const store = useStore();
		const setSites = ref([]) as Record<any, any>;
		const type = ref("");
		const payment_types = ref([
			{ value: "E_PAY", label: "E_PAY" },
			{ value: "INVOICE", label: "INVOICE" },
			{ value: "PAY_EVER", label: "Payever" },
		]);
		// Given Site Form Interface
		const paymentMethod = ref<EditPaymentMethod>({
			name: "",
			status: false,
			payment_type: "",
			id: null,
			sites: [],
		});

		//Load all site
		const get_sites = computed(() => {
			return store.getters.getAllSites.data.sites;
		});

		watchEffect(() => {
			setSites.value = [];
			get_sites.value.forEach((element) => {
				setSites.value.push({
					value: element.id,
					label: element.name,
				});
			});
		});

		// Store Name in Danish and English
		const jsonNameFields = ref({}) as Record<any, any>;

		const loadLang = () => {
			const locales_data = Apollo.readQuery({
				query: GET_LOCALES,
			});
			locales_data.locales.forEach((element) => {
				locales.value.push({
					label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
					value: element.locale,
				});

				jsonNameFields.value[element.locale] = { name: "" };
			});
		};

		loadLang();

		// On change input value
		const changeLang = (e: string) => {
			paymentMethod.value.name = jsonNameFields.value[e].name;
		};

		// update on change value input
		const updateValue = (e: string) => {
			jsonNameFields.value[locale.value].name = e;
		};

		// On change image
		const handleChange = async (event: Record<any, any>) => {
			loading.value = true;
			const media = event.target.files;
			const response = await store.dispatch(Actions.CREATE_MEDIA, media);
			const images = JSON.parse(response);
			images.forEach((element) => {
				previewImage.value.push({ src: element.asset_path, id: element.id });
			});
			loading.value = false;
		};

		// Remove Media Function
		const removeMedia = (index: number, media_id: number) => {
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_DELETE_RECORD")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loading.value = true;
					const resp = await store.dispatch(Actions.REMOVE_MEDIA, media_id);
					if (resp == "true") {
						previewImage.value.splice(index, 1);
						Notify.success(i18n.t("message.MEDIA_REMOVED_SUCCESSFULLY"));
					}
					loading.value = false;
				})
				.catch(() => {
					loading.value = false;
				});
		};

		// Show Media
		const showMedia = async (num: number) => {
			const formData = {
				previewImage: previewImage.value,
				visible: true,
				index: num,
			};
			await store.dispatch(Actions.TRIGGER_ACTION, { formData: formData, type: "imageProps" });
		};

		// Submit handler Request
		const submitHandlerForm = async () => {
			editPaymentMethodForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					for (const i in jsonNameFields.value) {
						if (jsonNameFields.value[i].name == "") {
							jsonNameFields.value[i].name = paymentMethod.value.name;
						}
					}
					const formData = {
						status: paymentMethod.value.status == false ? 0 : 1,
						name: JSON.stringify(jsonNameFields.value),
						type: paymentMethod.value.payment_type,
						id: paymentMethod.value.id,
						sites: paymentMethod.value.sites,
						image: previewImage.value.length != 0 ? previewImage.value[0].id : null,
					};

					loading.value = true;
					await Apollo.mutate({
						mutation: UPDATE_PAYMENT_METHOD,
						variables: { input: formData },
						update: (store, { data: { update_payment_method } }) => {
							const pervious_record = store.readQuery({
								query: GET_PAYMENT_METHODS,
							}) as Record<any, any>;
							store.writeQuery({
								query: GET_PAYMENT_METHODS,
								data: {
									payment_methods: [...pervious_record.payment_methods],
								},
								variables: {
									id: update_payment_method.id,
								},
							});
							modal.value.hide();
							loading.value = false;
							Notify.success(`${i18n.t("message.RECORD_UPDATED_SUCCESSFULLY")}`);
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
			type.value = "";
			locale.value="da";
			previewImage.value = [];
			editPaymentMethodForm.value?.resetFields();
			for (const i of Object.getOwnPropertyNames(jsonNameFields.value)) {
				jsonNameFields.value[i].name = "";
			}
		};

		// Emitter To Open Model
		emitter.on("editPaymentMethodAction", (payment_method: Record<any, any>) => {
			resetForm();
			paymentMethod.value.id = payment_method.id;
			paymentMethod.value.status = payment_method.status == 1 ? true : false;
			paymentMethod.value.payment_type = payment_method.type;
			const json_decode_name = JSON.parse(payment_method.name);
			for (const i in json_decode_name) {
				i == "da" ? (paymentMethod.value.name = json_decode_name[i].name) : "";
				jsonNameFields.value[i].name = json_decode_name[i].name;
			}

			if (payment_method.image) {
				previewImage.value.push({
					id: payment_method.image.id,
					src: payment_method.image.file_path,
				});
			}
			if (payment_method.sites.length != 0) {
				payment_method.sites.forEach((element) => {
					paymentMethod.value.sites.push(element.id);
				});
			}
			//payment_method.image != null ? previewImage.value = payment_method.image : []
			modal.value = new Modal(document.getElementById("modal_edit_payment_method")) as HTMLElement;
			modal.value.show();
		});

		return {
			loading,
			paymentMethod,
			editPaymentMethodForm,
			previewImage,
			setSites,
			payment_types,
			type,
			locales,
			locale,
			changeLang,
			removeMedia,
			handleChange,
			submitHandlerForm,
			showMedia,
			updateValue,
		};
	},
});
