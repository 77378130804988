import { gql } from "graphql-tag";

export const PAYMENT_METHOD_FIELDS = gql`
  fragment PaymentMethodFields on PaymentMethod {
		id
		name
    path
		status
		position
		type
    image  {
        id
        file_path
    }
    sites {
      id
    }
    created_at
	}
`;

export default { PAYMENT_METHOD_FIELDS };
