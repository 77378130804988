import { gql } from "graphql-tag";
import { PAYMENT_METHOD_FIELDS } from "./Fragments";

export const GET_PAYMENT_METHODS = gql`
    query GetPaymentMethod {
        payment_methods {
            ...PaymentMethodFields
        }
    }
    ${PAYMENT_METHOD_FIELDS},
`;

export const SEARCH_PAYMENT_METHODS = gql`
    query SearchPaymentMethod($search_key: String, $locale:String, $type:String , $date_from:String , $date_to:String) {
        search_payment_methods(search_key:$search_key, locale:$locale, type:$type , date_from:$date_from , date_to:$date_to){
            ...PaymentMethodFields
        }
    }
    ${PAYMENT_METHOD_FIELDS},
`;

export default { GET_PAYMENT_METHODS , SEARCH_PAYMENT_METHODS};