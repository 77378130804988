
import {defineComponent, ref, inject, watchEffect, onMounted, onUnmounted} from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { Apollo, Notify } from "@/core/services";
import { GET_PAYMENT_METHODS, SEARCH_PAYMENT_METHODS } from "../graphql/Queries";
import { DELETE_PAYMENT_METHOD, UPDATE_PAYMENT_POSITION } from "../graphql/Mutations";
import Search from "../../../../components/search/Search.vue";
import Table from "../../../../components/Table/Table.vue";
import InnerLoader from "../../../../components/InnerLoader.vue";
import { dropdown_handler } from "../../../../core/helpers/dropdownHandler";

export default defineComponent({
	name: "payment-methods-list",
	components: { Search, Table, InnerLoader },
	setup() {
		const store = useStore();
		const i18n = useI18n();
		const emitter: any = inject("emitter");
		const paymentMethods = ref([]);
		const systemLocale = ref();
		const pagination = ref({}) as Record<any, any>;
		const clickHandler = ref() as Record<any, any>;
		const loader = ref(false);
		const loading = ref(false);
		const permissions = store.getters.getPermissions;
		const activeIndex = ref(-1);
    const search_payment_query = ref(false);
    const observable = ref();
    const searchObservable = ref();
    const columns = ref([
			{
				label: "message.NAME",
				key: "name",
			},
			{
				label: "message.STATUS",
				key: "status",
			},
			{
				label: "message.POSITION",
				key: "position",
			},
			{
				label: "message.CREATED_AT",
				key: "created_at",
			},
			{
				label: "message.ACTIONS",
				key: "actions",
				textAlignment: "center",
			},
		]);

		watchEffect(() => {
			systemLocale.value = i18n.fallbackLocale.value;
		});

		const dropdownHandler = (index, id) => {
			activeIndex.value = index === id ? null : id;
		};
		const handleClickOutside = (event) => {
			if (!event.target.closest(".action-btn")) {
				activeIndex.value = -1;
			}
		};

		const handleCurrentChange = (num: number) => {
			clickHandler.value(num);
		};

		const getPaymentMethods = () => {
			loader.value = true;
      search_payment_query.value = false;
      searchObservable.value?.unsubscribe();
      observable.value = Apollo.watchQuery({
				query: GET_PAYMENT_METHODS,
				fetchPolicy: "network-only",
				nextFetchPolicy: "network-only",
				errorPolicy: "all",
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}
				clickHandler.value = getPaymentMethods;
				paymentMethods.value = data.payment_methods;
				loader.value = false;
			});
		};

		onMounted(() => {
			getPaymentMethods();
			document.addEventListener("click", handleClickOutside);
		});

    const searchHandler = () => {
      search_payment_query.value = true;
      const searchData = store.getters.getSearchData;
      observable.value?.unsubscribe();
      loader.value = true;
      searchObservable.value = Apollo.watchQuery({
        query: SEARCH_PAYMENT_METHODS,
        errorPolicy: "all",
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
        variables: {
          search_key: searchData.data.input,
          locale: systemLocale.value,
        },
      }).subscribe(({ data, errors }) => {
        if (errors) {
          loader.value = false;
        }
        paymentMethods.value = data.search_payment_methods;
        loader.value = false;
      });
    };

		const updatePosition = (position: number, new_position: number) => {
			if (new_position <= 0) {
				Notify.error(i18n.t("message.POSITION_MUST_BE_GREATER_THAN_0"));
				return;
			}
			if (position == new_position) {
				return;
			}
			const formData = {
				old_position: position,
				new_position: new_position,
			};
			loader.value = true;
			Apollo.mutate({
				mutation: UPDATE_PAYMENT_POSITION,
				variables: {
					input: formData,
				},
				update: (store, { data: { update_payment_position } }) => {
					loader.value = false;
					if (update_payment_position == true) {
						Notify.success(`${i18n.t("message.POSITION_CHANGED_SUCCESSFULLY")}`);
            search_payment_query.value ? searchHandler() : getPaymentMethods()
          }
				},
			}).catch(() => {
				loader.value = false;
			});
		};

		const handleEdit = (payment_method: Record<any, any>) => {
			emitter.emit("editPaymentMethodAction", payment_method);
		};


		const handleDelete = (id: number) => {
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_DELETE_RECORD")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loader.value = true;
					await Apollo.mutate({
						mutation: DELETE_PAYMENT_METHOD,
						variables: { id: id },
						update: (store, { data: { delete_payment_method } }) => {
              search_payment_query.value ? searchHandler() : getPaymentMethods()
							loader.value = false;
						},
					});
					Notify.success(i18n.t("message.RECORD_DELETED_SUCCESSFULLY"));
				})
				.catch(() => {
					loader.value = false;
				});
		};

		const exportFile = (data) => {
			const searchData = store.getters.getSearchData;
			loading.value = true;
			const sub = Apollo.watchQuery({
				query: SEARCH_PAYMENT_METHODS,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				variables: {
					page: 0,
					limit: 1,
					filter: searchData?.data?.select ? searchData?.data?.select : "",
					search_key: searchData?.data?.input ? searchData?.data?.input : "",
					locale: systemLocale.value,
					type: data?.type,
					date_from: data?.dateFrom,
					date_to: data?.dateTo,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loading.value = false;
				} else if (data) {
					window.open(data?.search_payment_methods[0]?.path, "_blank");
					loading.value = false;
				}

				loading.value = false;
				sub.unsubscribe();
			});
		};

    onUnmounted(() => {
      observable.value?.unsubscribe();
      searchObservable.value?.unsubscribe();
    });

		return {
			systemLocale,
			pagination,
			paymentMethods,
			columns,
			permissions,
			loading,
			loader,
			exportFile,
			handleCurrentChange,
			searchHandler,
			getPaymentMethods,
			updatePosition,
			handleEdit,
			handleDelete,
			activeIndex,
			dropdownHandler,
			handleClickOutside,
		};
	},
});
